import React from 'react';
import { FixedSizeList as VList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ListGroup } from 'reactstrap';

import DocumentListItem from '../documentListItem';
import EmptyDocumentList from './EmptyDocumentList';
import DocumentsSkeletonList from './DocumentsSkeletonList';

import { useDocumentsQuery } from '../../../../../queries';
import { useDocumentContext } from '../../../../../context';
import ResponsiveLayout from '../../../../../layouts/ResponsiveLayout';

const DocumentList = () => {
  const {
    searchValue,
    selectedPartners,
    orderFilter,
    despatchFilter,
    errorFilter,
    unsentFilter,
    startDate,
    endDate,
    setResultCount,
  } = useDocumentContext();
  const variables = {
    searchValue,
    selectedPartners: selectedPartners && selectedPartners.map(p => p.value),
    orderFilter,
    despatchFilter,
    errorFilter,
    unsentFilter,
    startDate: startDate && startDate.format('YYYY-MM-DD'),
    endDate: endDate && endDate.format('YYYY-MM-DD'),
  };
  const { data, loading, error } = useDocumentsQuery({
    variables,
  });
  if (error) return error.message;
  if (loading || !data || !data.documents) return <DocumentsSkeletonList />;

  setResultCount(data.documents.length);

  if (data.documents.length === 0) return <EmptyDocumentList />;
  return <VirtualListWrapper data={data.documents} rowRenderer={DocumentListItem} />;
};

const VirtualListWrapper = ({ data, rowRenderer }) => (
  <div style={{ height: '100vh' }}>
    <AutoSizer>
      {({ height, width }) => <ResponsiveList data={data} height={height} width={width} rowRenderer={rowRenderer} />}
    </AutoSizer>
  </div>
);

const ResponsiveList = ({ data, height, width, rowRenderer }) => {
  return (
    <ResponsiveLayout
      renderDesktop={() => (
        <ListGroup flush>
          <VList itemData={data} height={height} itemCount={data.length} itemSize={85} width={width}>
            {rowRenderer}
          </VList>
        </ListGroup>
      )}
      renderMobile={() => (
        <VList itemData={data} height={height} itemCount={data.length} itemSize={225} width={width}>
          {rowRenderer}
        </VList>
      )}
    />
  );
};

export default DocumentList;
