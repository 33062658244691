import React from 'react';
import { Row } from 'reactstrap';
import StatisticCard from './StatisticsCard';
import StatisticsSkeleton from './StatisticsSkeleton';
import { useActivityQuery } from '../../../../../queries';
import { useDocumentContext } from '../../../../../context';

const StatisticsCards = () => {
  const {
    searchValue,
    selectedPartners,
    orderFilter,
    despatchFilter,
    errorFilter,
    unsentFilter,
    startDate,
    endDate,
  } = useDocumentContext();
  const { data, loading, error } = useActivityQuery({
    variables: {
      searchValue,
      selectedPartners: selectedPartners && selectedPartners.map(p => p.value),
      orderFilter,
      despatchFilter,
      errorFilter,
      unsentFilter,
      startDate: startDate && startDate.format('YYYY-MM-DD'),
      endDate: endDate && endDate.format('YYYY-MM-DD'),
    },
  });
  if (error) return error.message;
  if (loading || !data || !data.activity) return <StatisticsSkeleton />;

  const ec = parseInt(data.activity.erroredCount);
  const uc = parseInt(data.activity.untransmittedCount);
  const oc = parseInt(data.activity.orderCount);
  const dc = parseInt(data.activity.despatchAdviceCount);
  const total = ec + uc + oc + dc;
  const ocp = (oc / (oc + dc)) * 100;
  const dcp = (dc / (oc + dc)) * 100;
  const ecp = total ? Math.trunc((parseInt(ec) / total) * 100) : 0;
  const ucp = total ? Math.trunc((parseInt(uc) / total) * 100) : 0;

  return (
    <Row>
      <StatisticCard
        title={'Razmijenjeni dokumenti'}
        text={oc + dc}
        leftBar={{ label: 'Narudžba', color: 'primary', value: oc, percent: ocp }}
        rightBar={{ label: 'Otpremnica', color: 'success', value: dc, percent: dcp }}
      />
      <StatisticCard
        title={'Čekaju preuzimanje'}
        text={ucp + '%'}
        leftBar={{ label: 'Nepreuzeti', color: 'warning', value: uc, percent: ucp }}
        rightBar={{ label: 'Ostali', value: total - uc }}
      />
      <StatisticCard
        title={'Greška u prenosu'}
        text={ecp + '%'}
        leftBar={{ label: 'Greške', color: 'danger', value: ec, percent: ecp }}
        rightBar={{ label: 'Ostali', value: total - ec }}
      />
    </Row>
  );
};

export default StatisticsCards;
