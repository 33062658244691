import React from 'react';
import { Row } from 'reactstrap';

import { FilterSearchBar, FilterDateRangePicker } from './components';
import { useDocumentContext } from '../../../../../context';

const MobileFilterCard = () => {
  const { searchValue, setSearchValue } = useDocumentContext();

  return (
    <div style={{ padding: '15px' }}>
      <Row style={{ marginBottom: '15px' }}>
        <FilterSearchBar value={searchValue} onChange={setSearchValue} />
      </Row>
      <Row>
        <FilterDateRangePicker />
      </Row>
    </div>
  );
};

export default MobileFilterCard;
