const context = require.context('../../../../../assets/logos', true, /.png$/);

const logos = {};

context.keys().forEach(key => {
  const partnerID = key
    .split('./')
    .pop()
    .substring(0, key.length - 6);
  logos[partnerID] = context(key);
});

export default logos;
