import React from 'react';
import Select from 'react-select';
import { usePartnersQuery } from '../../../../../../queries';
import { Avatar } from '../../../../../../vibe';
import { Logos } from '../../utils';

const renderOption = ({ value, label, level }) => {
  const img = !!Logos[value] && level && Logos[value];
  return (
    <div key={value}>
      {level === 1 ? (
        <span>
          <Avatar size={'small'} image={img} /> <strong key={2}>{label}</strong>
        </span>
      ) : (
        <small style={{ marginLeft: '1.5em' }}>{label}</small>
      )}
    </div>
  );
};

const PartnerSelect = ({ selectedPartners, setSelectedPartners }) => {
  const { loading, data, error } = usePartnersQuery({ variables: {} });
  if (error) return error.message;

  const handleChange = partners => {
    setSelectedPartners(partners);
  };
  return (
    <Select
      isMulti={true}
      isLoading={loading}
      value={selectedPartners}
      onChange={handleChange}
      placeholder={'Partneri...'}
      isSearchable={false}
      formatOptionLabel={renderOption}
      options={
        loading
          ? []
          : data.partners.map(p => {
              return { value: p.id, label: p.name, level: p.level };
            })
      }
    />
  );
};

export default PartnerSelect;
