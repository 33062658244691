import Dashboard from './pages/Dashboard';

//App Imports
import Documents from './pages/Documents';
import Partners from './pages/Partners';
import Users from './pages/Users';

const pageList = [
  {
    name: 'Dashboard',
    path: '/home',
    component: Dashboard,
  },
  {
    name: 'Dokumenti',
    path: '/app/documents',
    component: Documents,
  },
  {
    name: 'Partneri',
    path: '/app/partners',
    component: Partners,
  },
  {
    name: 'Admin',
    path: '/app/admin',
    admin: true,
    component: Users,
  },
];

export default pageList;
