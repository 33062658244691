import React, { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { useDocumentContext } from '../../../../../../context';
import moment from 'moment';

import ResponsiveLayout from '../../../../../../layouts/ResponsiveLayout';

const ResponsiveDateRangePicker = () => {
  const [focusedInput, setFocusedInput] = useState(null);
  const { startDate, setStartDate, endDate, setEndDate } = useDocumentContext();

  const handleDateChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const handleFocusChange = focusedInput => {
    setFocusedInput(focusedInput);
  };

  return (
    <ResponsiveLayout
      renderDesktop={() => (
        <FilterDateRangePicker
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          onDatesChange={handleDateChange}
          onFocusChange={handleFocusChange}
        />
      )}
      renderMobile={() => (
        <FilterDateRangePicker
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          onDatesChange={handleDateChange}
          onFocusChange={handleFocusChange}
          orientation="vertical"
          withFullScreenPortal
        />
      )}
    />
  );
};

const FilterDateRangePicker = props => {
  return (
    <DateRangePicker
      startDateId="startDate"
      endDateId="endDate"
      showClearDates
      small
      showDefaultInputIcon
      minimumNights={0}
      initialVisibleMonth={() => moment().subtract(1, 'month')}
      isOutsideRange={day => moment().diff(day) < 0}
      {...props}
    />
  );
};

export default ResponsiveDateRangePicker;
