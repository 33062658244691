import React, { useState, useEffect, useContext, createContext } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { useApolloClient } from '@apollo/react-hooks';
import { userQueryArgs } from '../queries';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

export const Context = createContext();

export const Provider = props => {
  const client = useApolloClient();
  const { children } = props;

  const [authUser, setAuthUser] = useState(null);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const clearState = () => {
    setError(null);
    setRedirectToReferrer(false);
    setAuthUser(null);
  };

  const doSignInWithEmailAndPassword = async (email, password) => {
    try {
      setLoading(true);
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const doSignOut = async () => {
    await firebase.auth().signOut();
  };

  const doPasswordReset = async email => {
    return await firebase.auth().sendPasswordResetEmail(email);
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const args = userQueryArgs(user.uid);
        const { data } = await client.query(args);
        setAuthUser({ name: data.user.name, role: data.user.role, company: data.user.company, ...user });
        setRedirectToReferrer(true);
      } else {
        clearState();
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [client]);

  // Make the context object:
  const authContext = {
    authUser,
    redirectToReferrer,
    loading,
    error,
    setError,
    doSignInWithEmailAndPassword,
    doSignOut,
    doPasswordReset,
  };

  // pass the value in provider and return
  return <Context.Provider value={authContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export const useAuthContext = () => useContext(Context);
