import React from 'react';
import { Col } from 'reactstrap';

import { Colors } from '../../utils';
import { GoFile } from 'react-icons/go';

const DocumentStatus = ({ doc }) => {
  let iconColor = !doc.transmitted ? Colors.yellow : Colors.green;
  iconColor = doc.errored ? Colors.red : iconColor;
  return (
    <Col md={1}>
      <p className="text-muted" style={{ marginBottom: '3px' }}>
        Status
      </p>
      <GoFile className={'ml-2'} size={28} color={iconColor} />
    </Col>
  );
};

export default DocumentStatus;
