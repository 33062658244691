import React from 'react';
import { Col } from 'reactstrap';

import { Avatar } from '../../../../../../vibe';
import { Logos } from '../../utils';

const PartnerLogos = ({ doc }) => {
  let senderID = doc.sender.parent ? doc.sender.parent.id : doc.sender.id;
  let recipientID = 0;
  if (doc.recipient && doc.recipient.parent) recipientID = doc.recipient.parent.id;
  else if (doc.recipient) recipientID = doc.recipient.id;
  //todo fix and generalize
  return (
    <Col md={1}>
      <Avatar image={Logos[senderID]} size={'large'} />
      <Avatar image={Logos[recipientID]} size={'large'} />
    </Col>
  );
};

export default PartnerLogos;
