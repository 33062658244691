import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

const StatisticsSkeleton = () => (
  <Row>
    <Col md={4} xs={12}>
      <Card>
        <CardHeader>
          <Skeleton />
        </CardHeader>
        <CardBody>
          <Skeleton height={120} />
        </CardBody>
      </Card>
    </Col>
    <Col md={4} xs={12}>
      <Card>
        <CardHeader>
          <Skeleton />
        </CardHeader>
        <CardBody>
          <Skeleton height={120} />
        </CardBody>
      </Card>
    </Col>
    <Col md={4} xs={12}>
      <Card>
        <CardHeader>
          <Skeleton />
        </CardHeader>
        <CardBody>
          <Skeleton height={120} />
        </CardBody>
      </Card>
    </Col>
  </Row>
);

export default StatisticsSkeleton;
