import React from 'react';
import { CustomInput } from 'reactstrap';

const FilterCheckBox = ({ label, checked, handleClick }) => (
  <CustomInput
    type="checkbox"
    id={'filter' + label}
    name="filterCheckbox"
    label={label}
    checked={checked}
    onChange={() => handleClick(!checked)}
  />
);

export default FilterCheckBox;
