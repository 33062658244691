import React from 'react';
import { Row } from 'reactstrap';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';

import { Avatar } from '../../../../../vibe';
import { Logos } from '../utils';

import moment from 'moment';

const DocumentTitle = ({ doc }) => {
  let title = doc.errored ? doc.name : `Dokument br. ${doc.entityID}`;
  if (title && title.length > 23) title = title.substr(0, 22) + '...';
  return <p>{title}</p>;
};

const Partner = ({ partner }) => {
  return (
    <span>
      <PartnerLogo partner={partner} />
      <span style={{ marginLeft: '3px' }}>
        <em>{partner && partner.name}</em>
      </span>
    </span>
  );
};

const PartnerLogo = ({ partner }) => {
  let partnerID = 0;
  if (partner) partnerID = partner.parent ? partner.parent.id : partner.id;
  return <Avatar size="small" image={Logos[partnerID]} />;
};

const getTypeInfo = doc => {
  if (doc.errored) return ['danger', 'sa greškom'];
  else if (!doc.transmitted) return ['warning', ''];
  else if (doc.name.includes('purchaseOrder')) return ['primary', 'narudžbe'];
  else if (doc.name.includes('despatchAdvice')) return ['success', 'otpremnice'];
  else return ['secondary', 'nepoznat'];
};

const MobileDocumentListItem = ({ doc }) => {
  const date = new Date(Number.parseFloat(doc.transmittedAt || doc.createdAt));
  const createdAt = moment.utc(date.toISOString()).calendar();

  const [typeClass, typeLabel] = getTypeInfo(doc);
  return (
    <Row>
      <div className={`p-3 bg-${typeClass} my-2 rounded`} style={{ margin: 'auto', width: '90%' }}>
        <Toast>
          <ToastHeader icon={typeClass}>
            <DocumentTitle doc={doc} />
          </ToastHeader>
          <ToastBody style={{ height: '125px' }}>
            <Partner partner={doc.sender} /> je poslao dokument {typeLabel} prema <Partner partner={doc.recipient} />{' '}
            <strong>{createdAt}</strong>
          </ToastBody>
        </Toast>
      </div>
    </Row>
  );
};

export default MobileDocumentListItem;
