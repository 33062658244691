import React from 'react';
import { Col } from 'reactstrap';

import moment from 'moment';

const DocumentCreatedAt = ({ doc }) => {
  const date = new Date(Number.parseFloat(doc.transmittedAt || doc.createdAt));
  const createdAt = moment.utc(date.toISOString()).calendar();

  return (
    <Col md={6}>
      <p className="text-muted" style={{ marginBottom: '3px' }}>
        Datum
      </p>
      {createdAt}
    </Col>
  );
};

export default DocumentCreatedAt;
