import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import okSVG from '../../../assets/images/undraw/undraw_mail_box_kd5i_blue.svg';
import problemSVG from '../../../assets/images/undraw/undraw_empty_xct9.svg';

const DocumentsCard = ({ count }) => {
  const img = count ? okSVG : problemSVG;

  return (
    <Card>
      <CardBody className="display-flex">
        <Row>
          <Col md={3}>
            <img src={img} style={{ width: '100%' }} alt="documents" aria-hidden={true} />
          </Col>
          <Col md={9}>
            <h2 className="h4">Današnji dokumenti</h2>
            {count ? (
              <p className="text-muted">
                Danas je razmijenjeno <strong>{count}</strong> dokumenata.
              </p>
            ) : (
              <p className="text-muted">Danas nije proslijeđen ni jedan dokument.</p>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default DocumentsCard;
