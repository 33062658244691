import ApolloClient from 'apollo-boost';
import * as firebase from 'firebase/app';

export default new ApolloClient({
  uri: `${process.env.REACT_APP_BACKEND}/graphql`,
  fetchOptions: {
    credentials: 'include',
  },
  request: async operation => {
    const token = await firebase.auth().currentUser.getIdToken(true);
    operation.setContext({
      headers: { authorization: token },
    });
  },
  onError: ({ networkError, graphQLErrors }) => {
    if (graphQLErrors) console.log('graphQLErrors', graphQLErrors);
    if (networkError) {
      console.log('networkError', networkError);
    }
    // todo contact slack with error
  },
});
