import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import okSVG from '../../../assets/images/undraw/undraw_server_status_5pbv_green.svg';
import problemSVG from '../../../assets/images/undraw/undraw_server_status_5pbv_yellow.svg';

const UnsentActivityCard = ({ count }) => {
  const img = count ? problemSVG : okSVG;

  return (
    <Card>
      <CardBody className="display-flex">
        <Row>
          <Col md={3}>
            <img src={img} style={{ width: '100%' }} alt="errors" aria-hidden={true} />
          </Col>
          <Col md={9}>
            <h2 className="h4">Neproslijeđeni dokumenti</h2>
            {count ? (
              <p className="text-warning">
                <strong>{count}</strong> dokumenata danas nisu proslijeđeni, provjerite da li su sve klijent aplikacije
                aktivne.
              </p>
            ) : (
              <p className="text-muted">Svi današnji dokumenti su uspijesno proslijeđeni.</p>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default UnsentActivityCard;
