import React, { useState, useContext, createContext } from 'react';
import moment from 'moment';

export const Context = createContext();

export const Provider = props => {
  const { children } = props;

  const [searchValue, setSearchValue] = useState('');
  const [orderFilter, setOrderFilter] = useState(false);
  const [despatchFilter, setDespatchFilter] = useState(false);
  const [errorFilter, setErrorFilter] = useState(false);
  const [unsentFilter, setUnsentFilter] = useState(false);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('week'));
  const [endDate, setEndDate] = useState(moment());
  const [resultCount, setResultCount] = useState(0);

  const clearFilters = () => {
    setSearchValue('');
    setOrderFilter(false);
    setDespatchFilter(false);
    setErrorFilter(false);
    setUnsentFilter(false);
    setSelectedPartners([]);
    setStartDate(null);
    setEndDate(null);
  };

  const isFiltered = () =>
    (searchValue && searchValue.length > 0) ||
    orderFilter ||
    despatchFilter ||
    errorFilter ||
    unsentFilter ||
    startDate ||
    endDate ||
    (selectedPartners && selectedPartners.length > 0);

  // Make the context object:
  const documentContext = {
    searchValue,
    setSearchValue,
    orderFilter,
    setOrderFilter,
    despatchFilter,
    setDespatchFilter,
    errorFilter,
    setErrorFilter,
    unsentFilter,
    setUnsentFilter,
    selectedPartners,
    setSelectedPartners,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    clearFilters,
    isFiltered,
    resultCount,
    setResultCount,
  };

  // pass the value in provider and return
  return <Context.Provider value={documentContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export const useDocumentContext = () => useContext(Context);
