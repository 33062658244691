import React from 'react';
import ToggleSidebarButton from './components/ToggleSidebarButton';
import { Navbar } from 'reactstrap';

const Header = ({ routes, location, toggleSidebar, isSidebarCollapsed }) => {
  const getPageTitle = () => {
    let name;
    routes.map(prop => {
      if (prop.path === location.pathname) {
        name = prop.name;
      }
      return null;
    });
    return name;
  };

  return (
    <header className="app-header">
      <SkipToContentLink focusId="primary-content" />
      <div className="top-nav">
        <Navbar color="faded" light expand="md" style={{ justifyContent: 'left' }}>
          <ToggleSidebarButton toggleSidebar={toggleSidebar} isSidebarCollapsed={isSidebarCollapsed} />
          <div className="page-heading">{getPageTitle()}</div>
        </Navbar>
      </div>
    </header>
  );
};

const SkipToContentLink = ({ focusId }) => {
  return (
    <a href={`#${focusId}`} tabIndex="1" className="skip-to-content">
      Skip to Content
    </a>
  );
};

export default Header;
