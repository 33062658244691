import React from 'react';
import { Col, Badge } from 'reactstrap';
import { Colors } from '../../utils';

const DocumentType = ({ doc }) => (
  <Col md={1}>
    <p
      className="text-muted"
      style={{
        marginBottom: '3px',
      }}
    >
      Vrsta
    </p>
    <span>
      {doc.name.includes('despatchAdvice') && <Badge style={{ backgroundColor: Colors.green }}>Otpremnica</Badge>}
      {doc.name.includes('purchaseOrder') && <Badge style={{ backgroundColor: Colors.blue }}>Narudžba</Badge>}
    </span>
  </Col>
);

export default DocumentType;
