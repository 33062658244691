export default {
  top: [
    {
      name: 'Pregled',
      url: '/home',
      icon: 'Home',
    },
    {
      name: 'Dokumenti',
      icon: 'File',
      url: '/app/documents',
    },
    {
      name: 'Partneri',
      icon: 'Users',
      url: '/app/partners',
    },
    {
      name: 'Admin',
      icon: 'Monitor',
      url: '/app/admin',
      admin: true,
    },
  ],
  bottom: [
    {
      name: 'Odjava',
      url: '/home',
      icon: 'LogOut',
      isLogoutButton: true,
    },
    {
      name: 'Korisnički nalog',
      url: '/dashboard',
      icon: 'User',
    },
  ],
};
