import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const QUERY = gql`
  query Documents(
    $searchValue: String
    $orderFilter: Boolean
    $despatchFilter: Boolean
    $errorFilter: Boolean
    $unsentFilter: Boolean
    $startDate: String
    $endDate: String
    $selectedPartners: [ID]
  ) {
    documents(
      searchValue: $searchValue
      orderFilter: $orderFilter
      despatchFilter: $despatchFilter
      errorFilter: $errorFilter
      unsentFilter: $unsentFilter
      startDate: $startDate
      endDate: $endDate
      selectedPartners: $selectedPartners
    ) {
      id
      name
      entityID
      relatedDocument
      type {
        id
        name
      }
      sender {
        id
        name
        parent {
          id
          name
        }
      }
      recipient {
        id
        name
        parent {
          id
          name
        }
      }
      transmitted
      received
      errored
      errorMessage
      errorResolved
      createdAt
      updatedAt
    }
  }
`;

export default ({ variables }) => useQuery(QUERY, { variables });
