import React from 'react';
import { Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

const DocumentsSkeletonList = () => (
  <ListGroup flush>{[[...Array(20)].map((_, index) => <DocumentsSkeletonListItem key={index} />)]}</ListGroup>
);

const DocumentsSkeletonListItem = () => (
  <ListGroupItem>
    <Row>
      <Col md={1}>
        <Skeleton circle={true} height={35} width={35} />
        <Skeleton circle={true} height={35} width={35} />
      </Col>
      <Col md={11}>
        <Skeleton height={15} />
        <Skeleton height={15} />
      </Col>
    </Row>
  </ListGroupItem>
);

export default DocumentsSkeletonList;
