import React from 'react';
import { useAuthContext, Provider as AuthProvider } from './authContext';
import { useDocumentContext, Provider as DocumentProvider } from './documentContext';
import { useWindowDimensions, Provider as WindowDimensionsProvider } from './windowDimensionsContext';

const ContextProvider = ({ children }) => {
  return (
    <AuthProvider>
      <WindowDimensionsProvider>
        <DocumentProvider>{children}</DocumentProvider>
      </WindowDimensionsProvider>
    </AuthProvider>
  );
};

export { useAuthContext, useDocumentContext, ContextProvider, useWindowDimensions };
