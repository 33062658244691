import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const MUTATION = gql`
  mutation CreateUser($user: UserInput) {
    createUser(user: $user) {
      id
      email
      role
    }
  }
`;

export default () => useMutation(MUTATION, { notifyOnNetworkStatusChange: true });
