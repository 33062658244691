import React from 'react';
import { Col } from 'reactstrap';

import { FaLongArrowAltRight } from 'react-icons/fa';

const DocumentTitle = ({ doc }) => {
  return (
    <Col md={6}>
      <p key={1} className="text-muted" style={{ marginBottom: '3px' }}>
        {doc.errored ? doc.name : 'Br. dokumenta ' + doc.entityID}
      </p>
      {doc.errored ? (
        doc.errorResolved ? (
          <p>
            <del>
              <span className="text-danger">{_pretty_error(doc.errorMessage)}</span>
            </del>{' '}
            <strong className="text-success">Rješeno</strong>
          </p>
        ) : (
          <p className="text-danger">{_pretty_error(doc.errorMessage)}</p>
        )
      ) : (
        [
          <span key={1}>{doc.sender.name}</span>,
          <FaLongArrowAltRight key={2} color={'grey'} />,
          <span key={3}>{doc.recipient.name}</span>,
        ]
      )}
    </Col>
  );
};

const _pretty_error = message => {
  if (!message) return 'Nepoznata greška';
  if (message.includes('Command failed: xmllint')) return 'Dokument nije validan XML po GS1 standardu.';
  else if (message.includes('Missing GTINS:')) {
    return message.replace('Missing GTINS:', 'Primaoc nema barkodove:');
  } else if (message.includes('ORA')) {
    return `Greška prilikom integracije dokumenta sa sistemom primaoca`;
  } else if (message.includes('Mapping error')) {
    return `Primaoc nije pronađen u sistemu`;
  } else if (message.includes('Duplicate document')) {
    return `Dupli dokument! Dokument sa ovim brojem je već ranije proslijeđen.`;
  } else return 'Greška prilikom prenosa dokumenta';
};
export default DocumentTitle;
