import React from 'react';
import { Row, Col, Card, CardHeader, CardBody, Progress } from 'reactstrap';
import { Colors } from '../utils';

const StatisticsCard = ({ title, text, leftBar, rightBar }) => (
  <Col md={4} xs={12}>
    <Card>
      <CardHeader>{title}</CardHeader>
      <CardBody>
        <h2 className="m-b-20 inline-block">
          <span>{text}</span>
        </h2>
        {rightBar.color && (
          <Progress multi>
            <Progress bar color={leftBar.color} value={leftBar.percent} />
            <Progress bar color={rightBar.color} value={rightBar.percent} />
          </Progress>
        )}
        {!rightBar.color && <Progress color={leftBar.color} value={leftBar.percent} />}

        <hr />
        <Row>
          <Col md={4}>
            <span className="h6" style={{ marginRight: 5 }}>
              <span
                className={'bg-' + leftBar.color + ' inline-block'}
                style={{ width: 10, height: 10, marginRight: 5 }}
              />
              {leftBar.label}
            </span>
            <small className="text-muted">{leftBar.value}</small>
          </Col>
          <Col md={4}>
            <span className="h6" style={{ marginRight: 5 }}>
              <span
                className={rightBar.color ? 'bg-' + rightBar.color + ' inline-block' : 'inline-block'}
                style={{ width: 10, height: 10, marginRight: 5, backgroundColor: Colors.grey }}
              />
              {rightBar.label}
            </span>
            <small className="text-muted">{rightBar.value}</small>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </Col>
);

export default StatisticsCard;
