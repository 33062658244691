import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query Partners {
    partners {
      id
      name
      parent {
        id
      }
      level
    }
  }
`;

export default ({ variables }) => useQuery(QUERY, { variables, notifyOnNetworkStatusChange: true });
