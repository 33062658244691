import React from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import StatisticsCards from './components/statistics';
import FilterCard from './components/filters';
import DocumentList from './components/documentsList';
import { FilterBreadcrumbs } from '../Documents/components/filters';

const DocumentPage = () => {
  return (
    <div>
      <Row>
        <Col md={12}>
          <FilterCard />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <StatisticsCards />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardHeader>
              Dokumenti <FilterBreadcrumbs />
            </CardHeader>
            <CardBody>
              <DocumentList />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DocumentPage;
