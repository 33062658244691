import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import NavSpacer from './components/NavSpacer';
import NavOverlay from './components/NavOverlay';
import NavDivider from './components/NavDivider';
import NavSingleItem from './components/NavSingleItem';
import NavDropdownItem from './components/NavDropdownItem';
import PageAlertContext from '../PageAlert/PageAlertContext';
import { useAuthContext } from '../../../context';

const SidebarNav = ({ logo, logoText, isSidebarCollapsed, toggleSidebar, nav }) => {
  const { authUser } = useAuthContext();

  const navItems = items => {
    return items.map((item, index) => itemType(item, index));
  };

  const itemType = (item, index) => {
    if (item.admin && (!authUser.role || authUser.role !== 'admin')) return null;
    if (item.children) {
      return <NavDropdownItem key={index} item={item} isSidebarCollapsed={isSidebarCollapsed} />;
    } else if (item.divider) {
      return <NavDivider key={index} />;
    } else {
      return <NavSingleItem item={item} key={index} />;
    }
  };

  const NavBrand = ({ logo, logoText }) => {
    return (
      <div className="site-logo-bar">
        <NavLink to="/" className="navbar-brand">
          {logo && <img src={logo} alt="" />}
          {logoText && <span className="logo-text">{logoText}</span>}
        </NavLink>
      </div>
    );
  };

  return (
    <PageAlertContext.Consumer>
      {consumer => {
        const hasPageAlertClass = consumer.alert ? 'has-alert' : '';
        return (
          <div>
            <div className={`app-sidebar ${hasPageAlertClass}`}>
              <NavBrand logo={logo} logoText={logoText} />
              <nav>
                <ul id="main-menu">
                  {navItems(nav.top)}
                  <NavSpacer />
                  {navItems(nav.bottom)}
                </ul>
              </nav>
            </div>
            {isSidebarCollapsed && <NavOverlay onClick={toggleSidebar} />}
          </div>
        );
      }}
    </PageAlertContext.Consumer>
  );
};

export default withRouter(SidebarNav);
