import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const MUTATION = gql`
  mutation DeleteUser($firebase_uid: String) {
    deleteUser(firebase_uid: $firebase_uid) {
      firebase_uid
    }
  }
`;

export default () => useMutation(MUTATION, { notifyOnNetworkStatusChange: true });
