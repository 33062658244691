import React, { createContext, useContext, useState, useEffect } from 'react';

const Context = createContext(null);

export const Provider = ({ children }) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <Context.Provider value={dimensions}>{children}</Context.Provider>;
};

export const useWindowDimensions = () => useContext(Context);
