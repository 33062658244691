import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, UncontrolledAlert, Spinner } from 'reactstrap';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { useAuthContext } from '../../../context';
import Background from '../../../assets/images/login_background.svg';
import Logo from '../../../assets/images/blue-logo.png';

const LoginPage = ({ location }) => {
  const { doSignInWithEmailAndPassword, redirectToReferrer, loading, error, setError } = useAuthContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const submitForm = e => {
    e.preventDefault();
    doSignInWithEmailAndPassword(email, password);
  };
  const { from } = location.state || { from: { pathname: '/' } };
  if (redirectToReferrer) return <Redirect to={from} />;
  return (
    <div
      style={{
        backgroundImage: `url(${Background})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center 110px',
        backgroundSize: '100%',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Container>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <UncontrolledAlert color="danger" isOpen={!!error} toggle={() => setError(null)}>
              Pogrešan username ili password
            </UncontrolledAlert>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 6, offset: 3 }} className="text-center">
            <img src={Logo} alt="" style={{ width: '160px', height: '80px' }} />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <Col>
              <h2>Login</h2>
            </Col>
            <Form className="form" onSubmit={e => submitForm(e)}>
              <Col>
                <FormGroup>
                  <Label>E-mail</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="E-mail"
                    value={email}
                    required
                    onChange={e => setEmail(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={password}
                    required
                    minLength={3}
                    onChange={e => setPassword(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col>
                <Link className="pull-right" style={{ marginBottom: '3vh' }} to={'password-forget'}>
                  Zaboravljen password?
                </Link>
              </Col>
              <Col>
                <Button color="primary" block>
                  {loading ? <Spinner size="sm" style={{ marginBottom: '3px' }} /> : <i className="fa fa-sign-in" />}
                  &nbsp;&nbsp;Prijavi se
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(LoginPage);
