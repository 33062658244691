import gql from 'graphql-tag';

const QUERY = gql`
  query User($firebase_uid: String) {
    user(firebase_uid: $firebase_uid) {
      id
      firebase_uid
      name
      email
      role
      company
    }
  }
`;

export default uid => ({ query: QUERY, variables: { firebase_uid: uid } });
