import React from 'react';
import emptySVG from '../../../../../assets/images/undraw/undraw_no_data_qbuo.svg';
import ResponsiveLayout from '../../../../../layouts/ResponsiveLayout';

const EmptyDocumentImage = ({ height, width }) => {
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <img height={height} width={width} src={emptySVG} alt="Nema dokumenata" />
      <h5 className="m-b text-muted">Nema razmijenjenih dokumenata u odabranom vremenskom periodu.</h5>
    </div>
  );
};

const EmptyDocumentList = () => {
  return (
    <ResponsiveLayout
      renderDesktop={() => <EmptyDocumentImage height={196} width={205} />}
      renderMobile={() => <EmptyDocumentImage height={120} width={130} />}
    />
  );
};

export default EmptyDocumentList;
