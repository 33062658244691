import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query Users {
    users {
      id
      firebase_uid
      name
      email
      role
      company
    }
  }
`;

export default ({ variables }) => useQuery(QUERY, { variables, notifyOnNetworkStatusChange: true });
