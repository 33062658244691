import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Collapse,
  Table,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { FaTrash, FaSpinner } from 'react-icons/fa';
import { FilterPartnerSelect } from '../Documents/components/filters';
import { useUsersQuery } from '../../../queries';
import { useUserMutation, useDeleteUserMutation } from '../../../queries';
import { useAuthContext } from '../../../context';

const UserContainer = () => {
  const { authUser } = useAuthContext();
  if (!authUser || !authUser.role || authUser.role !== 'admin') return <Redirect to="/home" />;
  return (
    <div>
      <Row>
        <Col md={{ size: 10, offset: 1 }}>
          <UserForm />
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 10, offset: 1 }}>
          <UserList />
        </Col>
      </Row>
    </div>
  );
};

const UserForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [createUser, { data, loading, error }] = useUserMutation();
  const [deleteData, setDeleteData] = useState(null);
  console.log('deleted user', deleteData);
  const handleCollapse = () => setIsOpen(open => !open);

  const handleSubmit = async () => {
    createUser({
      variables: {
        user: { email, password, name },
      },
    });
  };

  const clearFields = () => {
    setEmail('');
    setPassword('');
    setName('');
  };

  return (
    <Card>
      <CardHeader>Dodaj korisnika</CardHeader>
      <CardBody>
        <Form>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="userEmail">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="userEmail"
                  placeholder="Unesite E-mail..."
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="userPassword">Password</Label>
                <Input
                  type="password"
                  name="password"
                  id="userPassword"
                  placeholder="Unesite Password..."
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Collapse isOpen={isOpen}>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="userDisplayName">Display name</Label>
                  <Input
                    type="text"
                    name="userDisplayName"
                    id="userDisplayName"
                    placeholder="Unesite ime korisnika..."
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="userPartners">Dodijeljeni partneri</Label>
                  <small>Trenutno nije u funkciji</small>
                  <FilterPartnerSelect
                    id="userPartners"
                    selectedPartners={selectedPartners}
                    setSelectedPartners={setSelectedPartners}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Collapse>
          <Row>
            <Col md={{ offset: 8, size: 4 }} className="text-right">
              <Button
                color="primary"
                style={{ width: '75px' }}
                onClick={e => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                {loading ? <FaSpinner className="fa-spin" /> : 'Dodaj'}
              </Button>
              <Button
                color="secondary"
                style={{ width: '75px', marginLeft: '10px' }}
                onClick={e => {
                  e.preventDefault();
                  clearFields();
                }}
              >
                Obriši
              </Button>
              <Button onClick={handleCollapse} color="link" style={{ marginLeft: '10px' }}>
                Više
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

const UserActions = ({ user }) => {
  const { authUser } = useAuthContext();
  const [deleteUser, { data, loading, error }] = useDeleteUserMutation();
  if (!authUser) return null;
  if (user.role === 'admin') return null;

  const handleDelete = () => {
    deleteUser({ variables: { firebase_uid: user.firebase_uid } });
  };

  if (error) return error.message;

  return (
    <Button color="danger" onClick={handleDelete}>
      {loading ? <FaSpinner className="fa-spin" /> : <FaTrash />}
      &nbsp;{data ? 'Izbrisan' : 'Izbriši'}
    </Button>
  );
};

const UserList = () => {
  const { data, loading, error, fetchMore } = useUsersQuery({ variables: {} });
  if (error) return error.message;
  if (loading || !data || !data.users) return 'Loading...';
  return (
    <Card>
      <CardBody>
        Lista korisnika
        <Table hover>
          <thead>
            <tr>
              <th>#</th>
              <th>E-Mail</th>
              <th>Username</th>
              <th>Uloga</th>
              <th>Akcije</th>
            </tr>
          </thead>
          <tbody>
            {data.users.map((user, index) => (
              <tr key={user.id}>
                <td>{index + 1}</td>
                <td>{user.email}</td>
                <td>{user.name}</td>
                <td>{user.role}</td>
                <td>
                  <UserActions user={user} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default UserContainer;
