import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import problemSVG from '../../../assets/images/undraw/undraw_notify_88a4.svg';
import okSVG from '../../../assets/images/undraw/undraw_Mail_sent_qwwx_blue.svg';

const ErrorsCard = ({ count }) => {
  const img = count ? problemSVG : okSVG;

  return (
    <Card>
      <CardBody className="display-flex">
        <Row>
          <Col md={3}>
            <img src={img} style={{ width: '100%' }} alt="errors" aria-hidden={true} />
          </Col>
          <Col md={9}>
            <h2 className="h4">Greške u prenosu</h2>
            {count ? (
              <p className="text-danger">
                <strong>{count}</strong> dokumenata je danas imalo grešku prilikom prenosa.
              </p>
            ) : (
              <p className="text-muted">Nije bilo problema u prenosu dokumenata.</p>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ErrorsCard;
