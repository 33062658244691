import React from 'react';
import { Row, Col, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import FilterClearButton from './components/FilterClearButton';
import FilterCheckBox from './components/FilterCheckBox';
import FilterSearchBar from './components/FilterSearchBar';
import FilterPartnerSelect from './components/FilterPartnerSelect';
import FilterDateRangePicker from './components/FilterDateRangePicker';
import { useDocumentContext } from '../../../../../context';

const FilterCard = () => {
  const {
    searchValue,
    setSearchValue,
    selectedPartners,
    setSelectedPartners,
    orderFilter,
    setOrderFilter,
    despatchFilter,
    setDespatchFilter,
    errorFilter,
    setErrorFilter,
    unsentFilter,
    setUnsentFilter,
    isFiltered,
    clearFilters,
  } = useDocumentContext();

  return (
    <Card>
      <CardHeader>
        Filteri
        <FilterClearButton
          show={isFiltered()}
          onClick={e => {
            e.preventDefault();
            clearFilters();
          }}
        />
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={4}>
            <FilterSearchBar value={searchValue} onChange={setSearchValue} />
          </Col>
          <Col md={4}>
            <FilterDateRangePicker />
          </Col>
          <Col md={4}>
            <FilterPartnerSelect selectedPartners={selectedPartners} setSelectedPartners={setSelectedPartners} />
          </Col>
        </Row>
      </CardBody>
      <CardFooter style={{ justifyContent: 'center', display: 'flex' }}>
        <FilterCheckBox label={'Narudžbe'} checked={orderFilter} handleClick={setOrderFilter} />
        <FilterCheckBox label={'Otpremnice'} checked={despatchFilter} handleClick={setDespatchFilter} />
        <FilterCheckBox
          label={'Greške'}
          checked={errorFilter}
          handleClick={() => {
            setErrorFilter(f => !f);
            setUnsentFilter(false);
          }}
        />
        <FilterCheckBox
          label={'Nepreuzeti'}
          checked={unsentFilter}
          handleClick={() => {
            setErrorFilter(false);
            setUnsentFilter(f => !f);
          }}
        />
      </CardFooter>
    </Card>
  );
};

export default FilterCard;
