import React from 'react';
import { NavLink } from 'react-router-dom';

import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';

const GET_PARTNERS = gql`
  query Partners {
    partners {
      id
      name
    }
  }
`;

const Partners = () => {
  return (
    // <Query query={GET_PARTNERS}>
    //   {({ loading, error, data }) => {
    //     console.log('Querying...');
    //     if (loading) return `Loading ...`;
    //     if (error) return `Error! ${error}`;
    //     console.log('Query success');
    //     console.log(data.partners);
    //     return `Works? ${data.partners[0].name}`;
    //   }}
    // </Query>
    <div>
      <div className="m-t-xxl text-center">
        <h1 className="error-number">U izradi</h1>
        <h3 className="m-b">Prikaz partnera uskoro spreman</h3>
        <NavLink to={'/app/documents'}>Dokumenti!</NavLink>
      </div>
    </div>
  );
};

export default Partners;
