import React from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { DocumentsActivityCard, ErrorsActivityCard, UnsentActivityCard, ClientActivityCard } from './Activity';
import { Loader } from '../../vibe';
import { useActivityQuery } from '../../queries';
import { useAuthContext } from '../../context';
import moment from 'moment';

const Overview = ({ activity }) => {
  const { authUser } = useAuthContext();
  const heroStyles = {
    padding: '50px 0 70px',
  };
  const username = authUser && authUser.name ? authUser.name : 'Korisnik';
  return (
    <div>
      <Row>
        <Col md={6}>
          <div className="home-hero" style={heroStyles}>
            <h1>Dobrodošli, {username}.</h1>
            <p className="text-muted">
              Ova aplikacija je B2B portal, i omogućava vam pregled razmjene poslovnih dokumenata kao i uvid u stanje
              klijent aplikacija.
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <DocumentsActivityCard count={activity.documentCount} />
        </Col>
        <Col md={6}>
          <ErrorsActivityCard count={activity.erroredCount} />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <UnsentActivityCard count={activity.untransmittedCount} />
        </Col>
        <Col md={6}>
          <ClientActivityCard lastClientActivity={activity.lastClientActivity} />
        </Col>
      </Row>
    </div>
  );
};

const Dashboard = () => {
  const { data, loading, error } = useActivityQuery({
    variables: { startDate: moment().format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') },
  });
  if (error) return <ErrorAlert message={error.message} />;
  if (loading || !data || !data.activity) return <Loader type="puff" />;
  return <Overview activity={data.activity} />;
};

const ErrorAlert = ({ message }) => {
  return (
    <Alert color="danger">
      <h4 className="alert-heading">Greška!</h4>
      <p>Došlo je do greške pri preuzimanju dnevnih aktivnosti: ${message}</p>
      <hr />
      <p className="mb-0">
        Support kontakt E-mail: <strong>help@edi.ba</strong>
      </p>
    </Alert>
  );
};

export default Dashboard;
