import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import { ApolloProvider } from '@apollo/react-hooks';
import apolloClient from './apolloSetup';
import Login from './views/pages/Login';
import { PrivateRoute } from './layouts/components';
import { ContextProvider } from './context';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './vibe/scss/styles.scss';

import moment from 'moment';
import localization from 'moment/locale/bs';
moment.updateLocale(process.env.REACT_APP_LOCALE, localization);

export default function App() {
  return (
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <ContextProvider>
          <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute component={DashboardLayout} />
          </Switch>
        </ContextProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
}
