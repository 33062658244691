import React from 'react';
import { ListGroupItem } from 'reactstrap';
import DocumentListItem from './DocumentListItem';
import MobileDocumentListItem from './MobileDocumentListItem';
import ResponsiveLayout from '../../../../../layouts/ResponsiveLayout';

const ResponsiveDocumentListItem = ({ data, index, style }) => {
  let doc = data[index];
  return (
    <ResponsiveLayout
      renderDesktop={() => (
        <ListGroupItem key={doc.id} style={style}>
          <DocumentListItem doc={doc} />{' '}
        </ListGroupItem>
      )}
      renderMobile={() => (
        <div key={doc.id} style={style}>
          <MobileDocumentListItem doc={doc} />
        </div>
      )}
    />
  );
};

export default ResponsiveDocumentListItem;
