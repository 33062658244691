import React from 'react';
import { Route, Redirect } from 'react-router';
import { useAuthContext } from '../../context';

const PrivateRoute = ({ component: Component }) => {
  const { authUser } = useAuthContext();
  return (
    <Route
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
