import React from 'react';
import { Row, Col } from 'reactstrap';

import PartnerLogos from './components/PartnerLogos';
import DocumentTitle from './components/DocumentTitle';
import RelatedDocumentNumber from './components/RelatedDocumentNumber';
import DocumentCreatedAt from './components/DocumentCreatedAt';
import DocumentType from './components/DocumentType';
import DocumentStatus from './components/DocumentStatus';

const DocumentListItem = ({ doc }) => {
  return (
    <Row>
      <PartnerLogos key={1} doc={doc} />
      <DocumentTitle key={2} doc={doc} />
      <Col md={3}>
        <Row>
          <RelatedDocumentNumber key={3} doc={doc} />
          <DocumentCreatedAt key={4} doc={doc} />
        </Row>
      </Col>
      <DocumentType key={5} doc={doc} />
      <DocumentStatus key={6} doc={doc} />
    </Row>
  );
};

export default DocumentListItem;
