import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';

export default ({ value, onChange }) => {
  return (
    <InputGroup className={'md-10'}>
      <InputGroupAddon addonType="prepend">
        <span className="input-group-text" style={{ backgroundColor: '#fff', borderRightStyle: 'none' }}>
          <FaSearch color="#82888a" />
        </span>
      </InputGroupAddon>
      <Input
        type="text"
        placeholder="Pretraga..."
        aria-label="Search"
        value={value}
        style={{ borderLeftStyle: 'none' }}
        onChange={e => {
          e.preventDefault();
          onChange(e.target.value);
        }}
      />
    </InputGroup>
  );
};
