import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useDocumentContext } from '../../../../../../context';

const FilterBreadcrumbs = () => {
  const {
    searchValue,
    orderFilter,
    despatchFilter,
    errorFilter,
    unsentFilter,
    startDate,
    endDate,
    resultCount,
  } = useDocumentContext();
  const filterLabels = [
    orderFilter && 'Narudzbe',
    despatchFilter && 'Otpremnice',
    errorFilter && 'Greske',
    unsentFilter && 'Nepreuzeti',
  ];
  return (
    <Breadcrumb>
      {(startDate || endDate) && (
        <BreadcrumbItem>
          Od {(startDate && startDate.format('l')) || '_'} do {(endDate && endDate.format('l')) || '_'}
        </BreadcrumbItem>
      )}
      {filterLabels.some(f => !!f) && <BreadcrumbItem>{filterLabels.filter(f => !!f).join(', ')}</BreadcrumbItem>}
      {searchValue && searchValue.length > 0 && (
        <BreadcrumbItem>
          "<strong>{searchValue}</strong>"
        </BreadcrumbItem>
      )}
      <BreadcrumbItem>
        <strong>{resultCount}</strong> rezultata
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

export default FilterBreadcrumbs;
