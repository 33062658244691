import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const QUERY = gql`
  query Activity(
    $searchValue: String
    $orderFilter: Boolean
    $despatchFilter: Boolean
    $errorFilter: Boolean
    $unsentFilter: Boolean
    $startDate: String
    $endDate: String
    $selectedPartners: [ID]
  ) {
    activity(
      searchValue: $searchValue
      orderFilter: $orderFilter
      despatchFilter: $despatchFilter
      errorFilter: $errorFilter
      unsentFilter: $unsentFilter
      startDate: $startDate
      endDate: $endDate
      selectedPartners: $selectedPartners
    ) {
      documentCount
      orderCount
      despatchAdviceCount
      erroredCount
      untransmittedCount
      lastClientActivity
    }
  }
`;

export default ({ variables }) => {
  return useQuery(QUERY, { variables, notifyOnNetworkStatusChange: true });
};
