import React from 'react';
import { Col } from 'reactstrap';

const RelatedDocumentNumber = ({ doc }) => {
  return (
    <Col md={6}>
      {doc.relatedDocument && [
        <p key={1} className="text-muted" style={{ marginBottom: '3px' }}>
          {doc.type.name === 'order' ? 'Otpremnica' : 'Po narudžbi'}
        </p>,
        <span key={2}>{doc.relatedDocument}</span>,
      ]}
    </Col>
  );
};

export default RelatedDocumentNumber;
