import React from 'react';
import FilterCard from './FilterCard';
import MobileFilterCard from './MobileFilterCard';
import ResponsiveLayout from '../../../../../layouts/ResponsiveLayout';

const ResponsiveFilterCard = () => {
  return <ResponsiveLayout renderDesktop={() => <FilterCard />} renderMobile={() => <MobileFilterCard />} />;
};

export default ResponsiveFilterCard;
