import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import okSVG from '../../../assets/images/undraw/undraw_financial_data_es63_green.svg';
import problemSVG from '../../../assets/images/undraw/undraw_financial_data_es63_red.svg';
import moment from 'moment';

const ClientActivityCard = ({ lastClientActivity }) => {
  const date = new Date(Number.parseFloat(lastClientActivity));
  const timestamp = moment.utc(date.toISOString());
  const formattedLastContact = timestamp.calendar();

  const isLate = timestamp.isBefore(moment().subtract(10, 'minutes'));

  const img = isLate ? problemSVG : okSVG;
  return (
    <Card>
      <CardBody className="display-flex">
        <Row>
          <Col md={3}>
            <img src={img} style={{ width: '100%' }} alt="errors" aria-hidden={true} />
          </Col>
          <Col md={9}>
            <h2 className="h4">Status klijent aplikacije</h2>
            {isLate ? (
              <p className="text-danger">
                Zadnji kontakt klijent aplikacije: <strong>{formattedLastContact}</strong>. Provjerite da li je u
                funkciji.
              </p>
            ) : (
              <p className="text-muted">
                Zadnji kontakt klijent aplikacije: <strong>{formattedLastContact}</strong>.
              </p>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ClientActivityCard;
